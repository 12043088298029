import _assign from 'lodash/assign.js'
import useOrderStore from '~/stores/order'
import ChooseTourTypeModal from '@/components/modals/ChooseTourType.vue'
import LocationModal from '@/components/modals/Location.vue'
import LocationNotSupportedModal from '@/components/modals/LocationNotSupported.vue'

export function useOrderTools() {
    const showModal = inject('showModal')
    const orderStore = useOrderStore()
    const i18n = useI18n()
    const router = useRouter()

    function taxNote(
        taxRate = 19,
        customerGroup = orderStore.settings.customerGroup,
    ) {
        const mode =
            customerGroup === Enums.customerGroups.private
                ? 'included'
                : 'excluded'
        return i18n.order.taxNote(taxRate)[mode]
    }

    function showLocationModal(options = { scrollable: false }) {
        return new Promise((resolve, _reject) => {
            let result
            showModal(
                {
                    component: LocationModal,
                    params: { ...options?.params },
                    handleResult(value) {
                        result = value
                    },
                    afterHide() {
                        resolve(result)
                    },
                },
                options,
            )
        })
    }

    function showLocationNotSupportedModal(options = {}) {
        return new Promise((resolve) => {
            showModal(
                {
                    component: LocationNotSupportedModal,
                    params: {
                        zipCode: orderStore.settings.zipCode,
                        customerGroup: orderStore.settings.customerGroup,
                    },
                    afterHide: resolve,
                },
                _assign({ closeMethods: ['button'] }, options),
            )
        })
    }

    function showChooseTourTypeModal(options = {}, redirect = false) {
        return new Promise((resolve) => {
            let result
            orderStore.$patch({
                settings: {
                    tourType: '',
                },
            })

            showModal({
                component: ChooseTourTypeModal,
                params: {},
                handleResult(value) {
                    result = value
                },
                afterHide() {
                    orderStore.$patch({
                        settings: {
                            tourType: result,
                        },
                    })

                    if (result && redirect) {
                        router.push({
                            name: 'order',
                            query: orderStore.changedSettings,
                        })
                    }

                    resolve(result)
                },
                options,
            })
        })
    }

    return {
        store: orderStore,
        taxNote,
        showLocationModal,
        showLocationNotSupportedModal,
        showChooseTourTypeModal,
    }
}
